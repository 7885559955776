import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, ViewChild, ViewRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { MessageDialogComponent } from '@app/components/message-dialog/message-dialog.component';
import { CableType, CUSTOM_EXERCISE_TEMPLATE, StellaConnectionStatus, DashboardMode } from '@app/enums';
import { Exercise } from '@app/models/Exercise.model';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import * as StellaCommands from '@app/stella/services/StellaCommands';
import { EmergencyButtonClickedDialogComponent } from '@app/training/components/emergency-button-clicked-dialog/emergency-button-clicked-dialog.component';
import { EmgCalibrationComponent } from '@app/training/components/emg-calibration/emg-calibration.component';
import { OverThresholdDialogComponent } from '@app/training/components/over-threshold-dialog/over-threshold-dialog.component';
import { SignalWaveformDialogComponent } from '@app/training/components/signal-waveform-dialog/signal-waveform-dialog.component';
import { StimConfigurationSettingAdjustmentComponentComponent } from '@app/training/components/stim-configuration-setting-adjustment-component/stim-configuration-setting-adjustment-component.component';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CableDetails, ChannelSignal, CurrentType, SelectedMuscle } from '@app/types';
import { getColorForChannel } from '@app/utils/ColorDictionary';
import { adjustToRange, clamp } from '@app/utils/utils';
import { ExercisesQuery } from '@store/exercises';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { filter, flatMap, map, mergeMap, tap, takeWhile } from 'rxjs/operators';
import { ElectrostimGuide } from './ElectrostimGuideNew';
import * as ElectrostimProgram from './ElectrostimProgram';
import { Trigger } from './Trigger';
import { GTagService, AnalyticsCategory, AnalyticsAction } from '@app/shared/gtag.service';
import { validChannels } from '@app/training/ChannelResolver';
import { MoveLineTimeChart } from '@app/charts/MoveTimeLineChart';
import * as Color from 'color';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { ChartConfig } from '@app/charts/EgzoChart';
import { StimStat } from '@app/stella/services/StellaState';
import { ElectrostimConnection, ProgramModuleExecutorStellaStim, StimChannelIndex, StimModuleSchedulerInterval } from "@egzotech/exo-electrostim";
import { ComponentType } from '@angular/cdk/portal';
import { StimCalibrationProgramModuleComponent } from '@app/training/components/stim-calibration-program-module/stim-calibration-program-module.component';
import { NewExerciseContainerComponent } from '@app/training/components/new-exercise-container/new-exercise-container.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TrainingLikeComponent } from '@app/training/guards/CanDeactivate.guard';
import { Pages } from '@app/pages';
import { BatteryService } from '@app/shared/services/low-battery.service';
import { CableWarningDialogComponent } from '@app/training/components/cable-warning-dialog/cable-warning-dialog.component';
import { AuthService } from '@app/auth/services/auth.service';
import { Role } from '@app/models/Role.enum';
import { BaseTrainingComponent } from '../baseTrainingComponent';
import { DeepReadonly, StimStatus, ProgramModule } from "@egzotech/exo-electrostim";
import { ProgramController } from '@app/utils/electrostim/ProgramController';
import { ProgramConfiguration, ProgramConfigurationParametersValues } from '@app/utils/electrostim/ProgramConfiguration';
import { getPredefinedElectrostimConfigurations, toMicroseconds } from '@app/utils/electrostim/stim-programs';
import { TranslateService } from '@ngx-translate/core';
import { MoveLineTimeChart2 } from '@app/charts/MoveTimeLineChart2';


export const oldToNewProgramNamesMapping = {
  "mild_atrophy": "ems_mild_atrophy",
  "severe_atrophy": "ems_severe_atrophy",
  "ems_user_program": "ems_user_program",
  "atrophy": "ems_muscle_atrophy",
  "circulation": "ems_circulation",
  "incontinence_anal": "ems_fecal_incontinence",
  "incontinence_mixed": "ems_incontinence_mixed",
  "incontinence_urge": "ems_incontinence_urge",
  "incontinence_stress": "ems_incontinence_stress",
  "agonist_antagonist": "ems_agonist_antagonist",
  "power_training": "ems_power_training",
  "ems_sport_muscle_strenghtening": "ems_muscle_strengthening",
  "ems_sport_exercise_prep": "ems_exercise_prep",
  "ems_sport_active_recovery": "ems_active_recovery",
  "ems_sport_massage": "ems_massages",
  "ems_build_endurance": "ems_endurance_training",
  "grasp_and_release": "fes_grasp_and_release",
  "open_and_close": "fes_open_and_close",
  "arm_extension_support": "fes_arm_extension_support",
  "hand_to_mouth": "fes_hand_to_mouth",
  "pain_tens_conventional": "tens_conventional",
  "pain_tens_frequency": "tens_frequency_modulated",
  "pain_tens_burst": "tens_burst",
  "pain_tens_acupuncture": "tens_acupuncture",
  "pain_user_program": "tens_user_program",
  "grasp_and_release_triggered": "emgt_grasp_and_release",
  "open_and_close_triggered": "emgt_open_and_close",
  "arm_extension_support_triggered": "emgt_arm_extension_support",
  "hand_to_mouth_triggered": "emgt_hand_to_mouth",
  "incontinence_anal_trigger": "emgt_fecal_incontinence",
  "incontinence_emg_trigger": "emgt_incontinence",
  "emg_trigger": "emgt_emg_triggered_1ch",
  "ems_muscle_spasm_relaxation": "ems_muscle_spasm_relaxation",
  "ems_user_program_pelvic": "ems_user_program_pelvic",
  "ems_muscle_relaxation_pelvic_continuous": "ems_muscle_relaxation_pelvic_continuous",
  "ems_muscle_relaxation_pelvic_bursts": "ems_muscle_relaxation_pelvic_bursts"
}

const TICK_INTERVAL = 20;
const RESET_CHANNEL = [0, 0, 0, 0, 0, 0, 0, 0];

export enum State
{
  Stopped,
  Running,
  Paused,
  Waiting
}

export interface ElectrostimProgramDefinition {
  name: string;
  phases: any[];
  //calculatedDuration: number;
  adjustable?: object;
  incontinence?: boolean;
  details: {
    currentRange: {
      min: number;
      max: number;
    };
    channels?: number;
    repetitions?: number;
    duration: number;
    warmup?: boolean;
    cooldown?: boolean;
    calibration?: boolean;
    workTime: number;
    restTime: number;
    rampUp: number;
    rampDown: number;
    endRelaxTime: number;

    emgTriggered?: boolean;
    pulseDuration: number;
    frequency: number;
    currentType: CurrentType;
  };
}

class StellaBIOConnectionWapper implements ElectrostimConnection {
  handlers: Map<Function, Subscription> = new Map()

  constructor(private readonly stella: StellaDirectService) {

  }

  get electrostimStatusInterval() {
    // from stellaWebsocket.worker.ts:95 and from stellaWebsocket.worker.ts:120
    return 500;
  }

  addHandler(type: "stimStat" | "source", callback: (arg: any) => void) {
    if (this.handlers.has(callback)) {
      return;
    }

    if (type === "stimStat") {
      this.handlers.set(callback, this.stella.stimStat$.subscribe(callback));
    }
    else if (type === "source") {
      this.handlers.set(callback, this.stella.source$.subscribe(callback))
    }
  }

  removeHandler(type: "stimStat" | "source", callback: (arg: any) => void) {
    const subscription = this.handlers.get(callback);

    if (!subscription) {
      return;
    }

    this.handlers.delete(callback);
    return subscription.unsubscribe();
  }

  send(obj: object) {
    this.stella.send(obj);
  }
}

@Component({
  selector: 'sba-electrostim',
  templateUrl: './electrostim.component.html',
  styleUrls: ['./electrostim.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElectrostimComponent extends BaseTrainingComponent implements OnDestroy, TrainingLikeComponent {
  State = State; // for html

  chartConfig: ChartConfig = {
    preloadTime: 30, // s
    horizont: 30, // s
    ticks: 30,
    allowShifting: true
  };

  diffTime = 0;

  // Electrostimulation intensity for each channel in [mA] unit
  private electrostimIntensities = RESET_CHANNEL;

  state = State.Stopped;
  repetitions = 0;

  intervalRef;

  // Current electrostimulation definition program used by this training
  electrostimProgramDefinition: ElectrostimProgramDefinition;
  private electrostimProgramDefinitionParameters?: ProgramConfigurationParametersValues;

  private electrostimGuide: ElectrostimGuide;
  private stimChart: MoveLineTimeChart2;
  unsubscribe$ = new Subject<void>();

  electrostimOriginalExercise: Exercise;

  // Electrostimulation exercise information from the database
  private electrostimExercise: Exercise;

  private mvc: number;
  private maxes: number[];

  // Primary channel used as emg trigger
  private primaryTriggerChannel = 0;

  // Channels that are used for electrostimulation
  private electrostimChannels: number[];

  private subscription: Subscription;
  trigger = new Trigger;
  phaseIndex = 0;

  // Current electrostimulation program used by Stella BIO
  electrostimProgram: any[];

  // Information about attached cable to Stella BIO
  private cable: CableDetails;

  // Selected valid muscles with channels indices (with quality != 'none')
  private validMusclesWithChannels: SelectedMuscle[];

  // Selecte and non selected valid channels indices (with quality != 'none')
  private triggerChannels: SelectedMuscle[];

  forceEndTraining?: boolean;

  private overvoltageDialogRef: MatDialogRef<any>;
  private cableDialogRef: MatDialogRef<any>;
  @ViewChild(NewExerciseContainerComponent)
  private exerciseContainer: NewExerciseContainerComponent;

  // Keeps history of intensity changes
  private intensityChanges: {
    channel: number,
    time: number,
    level: number
  }[] = [];

  programModule: ProgramModule;
  programController: ProgramController;

  chartIconSrc: SafeResourceUrl ;

  private sanitizer: DomSanitizer;
  private timeElapsed: number = 0;
  private completedRepetitions: number;
  // required to prevent circular dependency
  private emergencyExit = false;
  private isInterrupted = false

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef,
    public readonly stella: StellaDirectService,
    private readonly exercise: CurrentExerciseService,
    private readonly exercises: ExercisesQuery,
    private readonly dialog: MatDialog,
    private readonly dashboard: DashboardService,
    private readonly gtag: GTagService,
    private readonly router: Router,
    private readonly batteryService: BatteryService,
    private readonly authService: AuthService,
    private readonly translateService: TranslateService,
    sanitizer: DomSanitizer) {
      super();
      router.routeReuseStrategy.shouldReuseRoute = () => false;
      router.events.subscribe(async (event) => {
        if (event instanceof NavigationStart) {
          if(this.state != State.Stopped) {
            this.pause();
          }
          if (event.navigationTrigger === 'popstate') {
            this.dialog.closeAll()
            this.isInterrupted = true
          }
        }
      });
      this.subscription = new Subscription();

      const connectionWrapper = new StellaBIOConnectionWapper(stella);

      this.programModule = new ProgramModule(
        new ProgramModuleExecutorStellaStim(connectionWrapper),
        new StimModuleSchedulerInterval(),
        // new Logger([
        //   new LoggerConsoleNode()
        // ])
    );

    this.programController = new ProgramController();

    this.subscription.add(this.route.paramMap
      .subscribe((params) => {
        this.electrostimOriginalExercise = this.getExerciseByName(params.get('name'));
      })
    );
    this.sanitizer = sanitizer;

    this.batteryService.batteryStatus$.subscribe(val => {
      if (val === 'LOW') {
        this.pause();
      }
    })
  }

  // Electrostim related

  onStart({ muscles }: { muscles: SelectedMuscle[] }) {
    this.validMusclesWithChannels = muscles;

    // Save connected cable
    this.cable = this.stella.state.getCable();

    // Handle connecting and disconnecting Stella BIO
    this.subscription.add(this.stella.connected$.pipe(
      tap(status => {
        if (status.connected === StellaConnectionStatus.CONNECTED) {
          this.stella.send(StellaCommands.STIM_PAUSE);
        }
        if (status.connected !== StellaConnectionStatus.CONNECTED) {
          if (this.state != State.Stopped) {
            this.pause();
          }
          // this.state = State.Stopped;
          this.updateChart();
          if (this.cdr && !(this.cdr as ViewRef).destroyed) {
            this.cdr.detectChanges();
          }
        }
      }
      )
    ).subscribe());

    this.subscription.add(this.stella.cable$.subscribe((c) => {
      // This if prevents showing dialog before calibration, settings, etc.
      if (this.electrostimProgram && c.code === CableType.NONE && !this.cableDialogRef) {
        this.pause();

        this.cableDialogRef = this.dialog.open(CableWarningDialogComponent);

        this.cableDialogRef.afterClosed().pipe(
            filter(res => Boolean(res)),
          ).subscribe(() => {
            this.endTraining();
            this.stop({ reason: 'cable' });
          });
      }
    }));

    // Handle electrostim interruptions
    this.subscription.add(this.stella.stimEnabled$
      .pipe(
        tap(val => {
          if (this.overvoltageDialogRef && !val) {
            this.overvoltageDialogRef.close(true);
            this.overvoltageDialogRef = null;
            return;
          }
          if (!this.overvoltageDialogRef && !this.cableDialogRef && val && !this.emergencyExit) {
            let component: ComponentType<any>;
            let interruption: string;

            // tslint:disable-next-line: no-bitwise
            if (val === (1 << 0)) {
              interruption = 'emergency_button';
              component = EmergencyButtonClickedDialogComponent;
            }

            // tslint:disable-next-line: no-bitwise
            if (val === (1 << 1)) {
              interruption = 'voltage_threshold';
              component = OverThresholdDialogComponent;
            }

            this.gtag.emit(AnalyticsCategory.TRAINING, AnalyticsAction.TRAINING_INTERUPTIONS, interruption);

            this.overvoltageDialogRef = this.dialog.open(component);

            this.overvoltageDialogRef.afterClosed()
              .subscribe(async res => {
                this.overvoltageDialogRef = null;

                if (!res) {
                  this.emergencyExit = true;
                  await this.stop({ reason: 'interruption' });
                } else {
                  this.stella.send(StellaCommands.STIM_PAUSE);
                }
              });
          }
        }),
        filter(val => val && this.state == State.Running),
        tap(_ => this.pause())
      )
      .subscribe()
    );

    // Handle status from Stella BIO
    this.subscription.add(this.stella.status$
      .pipe(
        filter(val => (this.state == State.Running && !!val)),
        tap(_ => {
          this.pause();
        })
      ).subscribe()
    );

    // Handle calculating time difference for electrostim
    // this.subscription.add(this.stella.stimStat$.pipe(
    //   distinctUntilChanged((prev, curr) => prev.tim === curr.tim),
    //   tap((c) => {
    //     if (this.state == State.Running) {
    //       //console.log(this.elapsedTimer.elapsedMicro.toFixed(0), c.tim.toFixed(0));
    //       this.diffTime = c.tim + c.latency - this.elapsedTimer.elapsedMicro;
    //       console.log(c);
    //     }
    //   })
    // ).subscribe());

    this.route.paramMap
      .pipe(
        tap(_ => {
          if (this.electrostimProgramDefinition) {
            this.stop({ reason: 'no-program' });
          }
        }),
        // TODO: get exercise from new program template
        map(params => this.getExerciseByName(params.get('name'))),
        flatMap(exercise => this.showDoNotUseAroundHeartWarning(exercise)),
        tap(exercise => {
          // Create electrostim program data
          this.electrostimProgramDefinition = {
            ...JSON.parse(JSON.stringify(exercise.steps)),
            name: exercise.name,
            calculatedDuration: this.getDuration(exercise.steps.details),
            type: exercise.type
          };

          this.chartIconSrc = this.getSvgIcon()

          const stimProgramName = oldToNewProgramNamesMapping[this.electrostimProgramDefinition.name]

          if (!stimProgramName) {
            throw new Error("Missing electrostim program named: " + this.electrostimProgramDefinition.name);
          }

          const stimProgram = getPredefinedElectrostimConfigurations()[stimProgramName];

          if (this.electrostimProgramDefinition.details?.currentRange) {
            this.electrostimProgramDefinition.details.currentRange.max = stimProgram.maxIntensity[0] * 1000;
          }

          // If an exercise from dashboard has a template then apply it to the program details
          if (this.dashboard.exercise?.template) {
            const programDetails = this.electrostimProgramDefinition.details;
            const templateDetails = this.dashboard.exercise.template.steps?.details as ElectrostimProgramDefinition["details"] ?? this.dashboard.exercise.template.details;

            this.electrostimProgramDefinitionParameters = {
              phases: stimProgram.parameters?.phases.map(v => !v ? null : {channels: {}}) ?? []
            };

            if (programDetails.repetitions !== templateDetails.repetitions) {
              this.electrostimProgramDefinitionParameters.phaseRepetition = templateDetails.repetitions;
            }

            if (programDetails.channels !== templateDetails.channels) {
              this.electrostimProgramDefinitionParameters.maxSupportedChannels = templateDetails.channels;
            }

            const mappedChannelParameters = {
              'workTime': 'plateauTime',
              'restTime': 'pauseTime',
              'rampUp': 'riseTime',
              'rampDown': 'fallTime',
              'duration': 'runTime',
              'frequency': 'pulseFrequency',
              'pulseDuration': 'pulseWidth'
            }

            this.electrostimProgramDefinitionParameters.phases = this.electrostimProgramDefinitionParameters.phases.map(phase => {
              if( !phase ) return null;
              const result = { ...phase };
              if( templateDetails.endRelaxTime) {
                result.endRelaxTime = templateDetails.endRelaxTime;
              }
              for( const key in mappedChannelParameters ) {
                if( programDetails[key] !== templateDetails[key]) {
                  result.channels[mappedChannelParameters[key]] = templateDetails[key];
                }
              }
              return result;
            });

            this.electrostimProgramDefinition.details = {
              ...programDetails,
              ...templateDetails
            };
          }
        }),
        filter(exercise => Boolean(exercise)),
        flatMap(_ => {
          const stimProgramName = oldToNewProgramNamesMapping[this.electrostimProgramDefinition.name]

          if (!stimProgramName) {
            throw new Error("Missing electrostim program named: " + this.electrostimProgramDefinition.name);
          }

          const stimProgram = getPredefinedElectrostimConfigurations()[stimProgramName];

          const channels = this.validMusclesWithChannels;

          if (this.electrostimProgramDefinition?.details?.emgTriggered) {
            if (stimProgram.maxIntensity.length === 1 || channels.length === stimProgram.maxIntensity.length) {
              channels[0].purpose = "both";
            }
            else if (channels.length === stimProgram.maxIntensity.length + 1) {
              channels[0].purpose = "trigger";
            }
          }

          // This step shows settings dialog and allow for adjusting program parameters
          return this.adjustSettings(stimProgram, {
            channels,
            allowEmgChannels: channels.length > stimProgram.maxIntensity.length,
            allowEmgEmsChannels: stimProgram.maxIntensity.length === 1 || channels.length === stimProgram.maxIntensity.length
          })
            .pipe(
              map(parameters => ({
                program: stimProgram,
                parameters
              }))
            );
        }),
        tap(p => {
          // Saving cloned valid muscles with channels (with quality != 'none')
          this.triggerChannels = this.validMusclesWithChannels.filter(v => v.purpose === "trigger" || v.purpose === "both");
          this.validMusclesWithChannels = this.validMusclesWithChannels.filter(v => v.purpose === "primary" || v.purpose === "both");

          // Extract cahnnel indices
          this.electrostimChannels = this.validMusclesWithChannels.map(v => v.channel);

          // Initial primary trigger channel - first selected channel
          this.primaryTriggerChannel = this.triggerChannels.length > 0 ? this.triggerChannels[0].channel : 0;

          if (p.parameters && p.parameters.phases && p.parameters.phases[0]) {
            const firstPhase = p.parameters.phases[0];
            if( firstPhase.endRelaxTime ) {
              this.electrostimProgramDefinition.details.endRelaxTime = firstPhase.endRelaxTime;
            }

            const firstChannel = firstPhase.channels ?? {};
            if (firstChannel.pulseFrequency) {
              this.electrostimProgramDefinition.details.frequency = firstChannel.pulseFrequency;
            }
            if (firstChannel.pulseWidth) {
              this.electrostimProgramDefinition.details.pulseDuration = firstChannel.pulseWidth;
            }
            if (firstChannel.runTime) {
              this.electrostimProgramDefinition.details.duration = firstChannel.runTime;
            }

            if( firstChannel.plateauTime ) {
              this.electrostimProgramDefinition.details.workTime = firstChannel.plateauTime
            }
            if( firstChannel.pauseTime ) {
              this.electrostimProgramDefinition.details.restTime = firstChannel.pauseTime
            }
            if( firstChannel.riseTime !== undefined ) {
               this.electrostimProgramDefinition.details.rampUp = firstChannel.riseTime
            }
            if( firstChannel.fallTime !== undefined ) {
              this.electrostimProgramDefinition.details.rampDown = firstChannel.fallTime
            }
          }
          if (this.electrostimProgramDefinition.name === 'ems_build_endurance'&&  p.parameters && p.parameters.phases && p.parameters.phases[1]) {
            const secondChannel = p.parameters.phases[1].channels;
            if (secondChannel.runTime) {
              this.electrostimProgramDefinition.details.duration = secondChannel.runTime;
            }
          }
          if (p.parameters && p.parameters.phaseRepetition) {
            this.electrostimProgramDefinition.details.repetitions = p.parameters.phaseRepetition;
          }
        }),
        tap(_ => {
          // Find exercise in database with the same name as electrostim program
          const ex = this.exercises.getAll().find(ec => ec.name === this.electrostimProgramDefinition.name);

          // If exercise not found in database then get data from custom exercise
          this.electrostimExercise = ex || this.exercises.getEntity(CUSTOM_EXERCISE_TEMPLATE);
        }),
        takeWhile(_ => !this.isInterrupted),
        flatMap(programWithParameters => {
            const channelMapping: { [key: number]: number } = {};
            let configChannel = 0;

            for (let i = 0; i < this.validMusclesWithChannels.length && programWithParameters.program.maxIntensity.length; i++) {
              channelMapping[this.validMusclesWithChannels[i].channel] = configChannel;
              configChannel = (configChannel + 1) % programWithParameters.program.maxIntensity.length;
            }
            const program = {...programWithParameters.program}
            if(programWithParameters.parameters && programWithParameters.parameters.phaseRepetition) {
              program.phasesRepetition = programWithParameters.parameters.phaseRepetition
            }
            this.programController.setConfiguration(program, {
              parameters: programWithParameters.parameters || undefined
            });

            console.log('[STIM CONFIGURATION]', this.programController.stimConfiguration);

            this.programModule.setProgram(this.programController.stimConfiguration);
            this.programModule.setChannelMapping(channelMapping);

            if (this.electrostimProgramDefinition.details.calibration && this.triggerChannels.length > 0) {
              // If program has calibration set then there is a need for EMG calibration
              this.adjustChartConfigForEmgTrigger(Math.round(Math.max(...this.programController.stimConfiguration.phases[0].channels.map(channel => channel.runTime)) * 1e-6));

              return this.runEmgCalibration()
                .pipe(
                  mergeMap(() => this.runStimCalibration(this.electrostimProgramDefinition))
                );
            }

            // Otherwise only electrostim calibration is needed
            return this.runStimCalibration(this.electrostimProgramDefinition);
        }),
        tap(_ => {
          // Create electrostim program for Stella BIO device
          this.electrostimProgram = ElectrostimProgram.createStimProgram(this.electrostimProgramDefinition, this.electrostimIntensities, true, this.electrostimChannels);

          this.repetitions = this.electrostimProgramDefinition.details.repetitions || 0;
        })
      )
      .subscribe({
        next: _ => {
          this.cdr.detectChanges();
        },
        error: err => {
          console.warn(err);
          console.log('[WARN] Back to muscle selection');
        }
      });

      this.programModule.onFinish = () => {
        this.stop({ reason: 'time' });
      }
  }

  // UI

  // old: showTensWarning
  showDoNotUseAroundHeartWarning<T>(exercise: T): Observable<T> {
    return this.dialog.open(MessageDialogComponent, {
      data: {
        prompt: 'electrostim.not_user_around_heart',
        translated: true,
        warning: true
      }
    })
      .afterClosed()
      .pipe(
        map(_ => exercise)
      );
  }

  // Electrostim related

  adjustSettings(program: DeepReadonly<ProgramConfiguration>, options: {
    channels?: SelectedMuscle[] | null,
    allowEmgChannels?: boolean
    allowEmgEmsChannels?: boolean
  }): Observable<ProgramConfigurationParametersValues | false | undefined> {
    const emptyProgramPhasesParameters = !program?.parameters?.phases || program.parameters.phases.every(phase => Object.keys(phase?.channels ?? {}).length === 0 && Object.keys(phase?.endRelaxTime ?? {}).length === 0)
    if(!program?.parameters?.phaseRepetition && emptyProgramPhasesParameters) {
      return of(undefined)
    }
    return this.dialog.open<
      StimConfigurationSettingAdjustmentComponentComponent,
      {
        program: DeepReadonly<ProgramConfiguration>,
        channels?: SelectedMuscle[] | null,
        allowEmgChannels?: boolean
        allowEmgEmsChannels?: boolean,
        existingParameters: ProgramConfigurationParametersValues,
        durationFixedModifier: number
      },
      ProgramConfigurationParametersValues | false | undefined
    >(StimConfigurationSettingAdjustmentComponentComponent, {
      minWidth: '50%',
      data: {
        channels: options.channels,
        program,
        existingParameters: this.electrostimProgramDefinitionParameters,
        durationFixedModifier: this.electrostimProgramDefinition.name === 'ems_build_endurance' ? 10 : 0,
        ...options
      }
    })
      .afterClosed()
      .pipe(
        map(result => result !== undefined && this.checkRejection(result)),
        tap((parameters: ProgramConfigurationParametersValues | false | undefined) => {
        //   if (newProgram.phases) {
        //     const phases = [...newProgram.phases];
        //     let duration = newProgram.details.duration;
        //     if (newProgram.details.warmup !== undefined && newProgram.details.warmup === false) {
        //       const el = phases.shift();
        //       duration -= el.duration;
        //     }
        //     if (newProgram.details.cooldown !== undefined && newProgram.details.cooldown === false) {
        //       const el = phases.pop();
        //       duration -= el.duration;
        //     }
        //     this.electrostimProgramDefinition = {
        //       ...newProgram,
        //       details: {
        //         ...newProgram.details,
        //         duration,
        //       },
        //       phases,
        //       calculatedDuration: this.getDuration({ ...newProgram.details, duration })
        //     };
        //     return;
        //   }
        //   this.electrostimProgramDefinition = {
        //     ...newProgram,
        //     calculatedDuration: this.getDuration(newProgram.details)
        //   };
        })
      );
  }

  private adjustChartConfigForEmgTrigger(burstTime: number) {
    this.chartConfig = {
      preloadTime: burstTime / 2, // s
      horizont: burstTime / 2, // s
      ticks: burstTime / 2,
      allowShifting: false
    };
  }

  // UI

  private checkRejection<T>(result: T | false) {
    if (!result) {
      this.exerciseContainer.restart();
      this.electrostimProgramDefinition = null;
      throw new Error('back_to_muscle_selection');
    }
    return result;
  }

  // Electrostim related

  private runEmgCalibration(): Observable<{
      max: number[],
      channel: number,
      selectedSecondaryChannel: number
    }> {
    return this.dialog.open(EmgCalibrationComponent, {
      width: '80%',
      data: {
        muscles: this.triggerChannels,
        channel: this.primaryTriggerChannel,
        recalibration: false
      }
    })
      .afterClosed()
      .pipe(
        map(result => this.checkRejection(result)),
        filter(Boolean),
        tap((result: {
          max: number[],
          channel: number,
          selectedSecondaryChannel: number
        }) => {
          this.maxes = result.max;
          this.primaryTriggerChannel = result.channel;
          // valid data is always on index 0 because we only support only one EMG channel in EMG Trigger training during calibration
          this.mvc = this.maxes[0];
          this.trigger.threshold = this.maxes[0] / 2;
        })
      );
  }

  // UI

  getRepetitionCounter(): string {
    // if (this.electrostimProgramDefinition.details && this.trigger) {
    //   const all = this.electrostimProgramDefinition.details.repetitions;
    //   return `${all - this.repetitions}/${all}`;
    // }

    // return '---';

    return `${this.programModule.repetition() + 1}/${this.programModule.repetitionCount()}`;
  }

  // Electrostim related

  private runStimCalibration(definition: ElectrostimProgramDefinition, recalibration: boolean = false): Observable<number[]> {
    return this.dialog.open(StimCalibrationProgramModuleComponent, {
      minWidth: '60%',
      data: {
        muscles: this.validMusclesWithChannels,
        program: definition,
        module: this.programModule,
        electrostimLevels: recalibration ? this.electrostimIntensities : null,
        noBackToMuscleSelection: recalibration
      }
    })
      .afterClosed()
      .pipe(
        map(result => this.checkRejection(result)),
        tap(intensities => {
          this.electrostimIntensities = intensities;

          // Filter valid channels by those who have more than zero intensity
          // This work only with assumption that electrostim calibration runs only once
          // per exercise, when run multiple times 'validMusclesWithChannels' will never
          // return to its initial state and will never again show all valid channels.
          // this.validMusclesWithChannels = this.validMusclesWithChannels.filter(m => intensities[m.channel] !== 0);

          this.electrostimChannels = this.validMusclesWithChannels.map(v => v.channel);
          this.configureProgramWithChartAndIntensity(definition);
          this.validMusclesWithChannels.forEach((m, i) => {
            this.intensityChanges.push({
              channel: m.channel,
              time: this.programModule.progress(StimStatus.Program) ?? 0,
              level: this.electrostimIntensities[m.channel]
            });
          });
          this.cdr.detectChanges();
        })
      );
  }

  updateTrigger(event) {
    if (event[0]) {
      this.trigger.value = event[0];

      if (this.trigger.isTriggered()) {
        this.programModule.trigger();
      }
    }
  }

  async handleThresholdChange(event) {
    // this.programModule.config.phases.map(p => p.trigger)
    //   .filter(p => p instanceof TriggerEmg)
    //   .forEach(p => (p as TriggerEmg).threshold = event);

    this.trigger.threshold = event;
  }

  private configureProgramWithChartAndIntensity(definition: ElectrostimProgramDefinition) {
    // Clamp all intesities to max program range
    this.electrostimIntensities.forEach(lv => {
      lv = clamp(lv, 0, definition.details.currentRange.max);
    });

    // create an electrostimGuide for the definition
    this.electrostimGuide = new ElectrostimGuide(this.programModule, { onlySingleRepetition: !!this.electrostimProgramDefinition.details.calibration });

    this.cdr.detectChanges();
  }

  // UI

  private getExerciseByName(name: string) {
    return this.exercises
      .getAll()
      .filter(ex => ex.type === 'electrostim' || ex.type === 'fes')
      .find(e => e.name === name);
  }

  // Electrostim related

  async start() {
    switch (this.state) {
      case State.Running:
        break;

      case State.Stopped:
        this.setState(State.Waiting);
        await this.exercise.initializeExercise(this.electrostimExercise.name || this.electrostimProgramDefinition.name, {
          module: 'electrostim',
          template: this.electrostimProgramDefinition,
          emgCalibration: this.maxes,
          emsCalibration: this.electrostimIntensities
        });
        this.exercise.start();
        this.exercise.update({
          threshold: new Array(8).fill(this.trigger.threshold),
          muscles: this.validMusclesWithChannels
        });

        this.setState(State.Running);
        this.programModule.start();
        this.updateChart();

        break;

      case State.Paused:
        this.setState(State.Running);
        this.programModule.start();
        this.updateChart();
        break;

      case State.Waiting:
        break;
    }
    super.onPlay();

    this.cdr.detectChanges();
  }

  async stop(opt: { reason: string }) {
    this.timeElapsed = this.programModule.progress(StimStatus.Program);
    if (this.electrostimProgramDefinition?.details?.emgTriggered) {
      this.completedRepetitions = this.programModule.repetition() + 1;
    }
    this.setState(State.Paused);
    this.programModule.stop();

    if (['interruption', 'no-program'].includes(opt.reason)) {
      return this.cleanup();
    }

    if (opt.reason === 'time' || opt.reason === 'cable') {
      if (opt.reason === 'time') {
        this.timeElapsed = this.programModule.config.programTime;
      }
      return await this.nextStep(true);
    } else {
      return await this.nextStep(false);
    }
  }

  pause() {
    // this.isStopped = this.electrostimProgramDefinition.details.emgTriggered;
    super.pause();
    this.setState(State.Paused);
    this.programModule.stop();
    this.cdr.detectChanges();
  }

  canPause() {
    return this.state == State.Running || this.state == State.Waiting;
  }

  handleTick() {
    if (this.state == State.Running) {
      // if (this.programModule.scheduler.isStopped()) {
      //   this.stop({ reason: 'time' });
      //   return;
      // }

      this.updateChart();
      this.cdr.detectChanges();
    }

    this.intervalRef = setTimeout(() => this.handleTick(), TICK_INTERVAL);
  }

  async updateExerciseData(): Promise<void> {
    await this.exercise.update({
      intensityChanges: this.intensityChanges,
      duration: this.timeElapsed,
      repetitions: this.completedRepetitions
    });
  }


  async ngOnDestroy()  {
    this.setState(State.Stopped);
    this.programModule.stop();
    this.programModule.reset();
    this.programModule.clearProgram();
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.exercise.stop();
    if (this.stimChart) {
      this.stimChart.destroy();
    }
  }

  calculateProgress() {
    if (this.electrostimProgramDefinition.details.calibration) {
      // EMG Triggered
      const repetitionTime = this.programModule.total() / this.programModule.repetitionCount();
      return Math.round((this.programModule.progress() % repetitionTime) / repetitionTime * 1000);
    }

    return Math.round(this.programModule.progress() / this.programModule.total() * 1000);
  }

  getDuration(programDetails: any): number {
    if (!programDetails) {
      return 0;
    }
    if (programDetails.emgTriggered) {
      return Math.round((programDetails.workTime + programDetails.restTime) / 1000) * 1000;
    }
    return programDetails.duration || 10 * 60 * 1e6;
  }

  private setState(state: State) {
    this.state = state;

    clearTimeout(this.intervalRef);

    switch (state) {
      case State.Waiting:
        this.intervalRef = setTimeout(() => this.handleTick(), TICK_INTERVAL);
        break;
      case State.Stopped:
        break;
      case State.Running:
        this.intervalRef = setTimeout(() => this.handleTick(), TICK_INTERVAL);
        break;
      case State.Paused:
        break;
    }
  }

  private async cleanup() {
    console.log(this.programModule.progress(StimStatus.Program))
    this.setState(State.Stopped);
    this.electrostimIntensities = RESET_CHANNEL;
    // if (this.electrostimGuide) {
    //   this.electrostimGuide.reset();
    // }
    this.endTraining();
    await this.updateExerciseData();
    await this.exercise.stop();

    if (this.cdr && !(this.cdr as ViewRef).destroyed) {
      this.cdr.detectChanges();
    }
  }

  private endTraining() {}

  // UI
  @ViewChild('lineChart') set onLineChart(lineChart: ElementRef) {
    if (!lineChart) {
      return;
    }
    this.stimChart = new MoveLineTimeChart2({
      chartConfig: this.chartConfig,
      container: lineChart.nativeElement as HTMLDivElement
    }, [], this.translateService);
    this.stimChart.setScale({ min: 0, max: 105 });

    this.electrostimGuide.chartPoints.forEach((p, index) => {
      if (this.electrostimChannels[index] === undefined) {
        return;
      }

      const color = getColorForChannel(this.electrostimChannels[index]);
      this.stimChart.createGuideLine(p.map(point => ({ x: point.x, y: point.y })), color.alpha(0.5).hex());
    });
  }

  openWaveformDetails() {
    this.dialog.open(SignalWaveformDialogComponent);
  }

  // Electrostim related

  calibrate() {
    if (this.canPause()) {
      this.pause();
    }

    this.runStimCalibration(this.electrostimProgramDefinition, true)
      .subscribe(() => {});
  }

  modifyLevel(event: { channel: number, value: number }) {
    this.intensityChanges.push({
      channel: event.channel,
      time: 0,//this.elapsedTimer.elapsedMicro,
      level: event.value
    });

    console.log(event.value);

    if (event.value > 10) {
      this.stella.send(StellaCommands.SET_OVERVOLTAGE_TRIGGER(70));
    }

    this.programModule.setIntensity(event.channel as StimChannelIndex, event.value/1000);

    // this.stella.send(StellaCommands.CHANGE_AMPLITUDE(event.channel, event.value));
    // this.programModule.setIntensity(event.channel as StimChannelIndex, event.value);
  }

  remainingTimeSeconds(): number {
    // return (this.electrostimProgramDefinition.calculatedDuration - this.elapsedTimer.elapsedMicro) / 1e6;

    return Math.max(0, (this.programModule.total() - this.programModule.progress(StimStatus.Program)) * 1e-6);
  }

  updateChart() {
    if (this.electrostimProgramDefinition.details.calibration) {
      // EMG Triggered
      const repetitionTime = this.programModule.total() / this.programModule.repetitionCount();
      this.stimChart.setTime((this.programModule.progress() % repetitionTime) * 1e-6);
    }
    else {
      this.stimChart.setTime(this.programModule.progress() * 1e-6);
    }
  }

  // UI

  async nextStep(forceEnd = false, nextExercise = true) {
    await this.updateExerciseData();

    if (this.dashboard.started && nextExercise) {
      this.dashboard.nextExercise();
    } else {
      if (this.dashboard.mode === DashboardMode.PATIENT) {
        await this.router.navigate([Pages.PATIENT_CALENDAR], { state: { force: forceEnd } });
      } else {
        await this.router.navigate([Pages.PATIENT_MEDICAL_CARD], { state: { force: forceEnd } });
      }
    }

  }

  async goBack() {
    await this.nextStep(false, false);
  }

  getSvgIcon() {
    switch ( this.electrostimProgramDefinition.details.currentType) {
      case 'RECTANGULAR': return this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/rectangle_simple.png');
      case 'TRAPEZOIDAL': return this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trapezoid_simple.png');
      case 'TRIANGULAR': return this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/triangle_simple.png');
      case 'TENS': return this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/rectangle_simple.png');
      default: return '' as never;
    }
  }
}
