import { TranslateService } from '@ngx-translate/core';
import { EgzoChart, ChartOptions, ChartConfig } from './EgzoChart';

interface LineChartOptions extends ChartOptions {
  channels: number;
  chartConfig: ChartConfig;
  series: number;
  includeMoveLine: boolean;
}

export class MoveLineTimeChart2 {
  private time: number;
  private animationFrame: (() => void) | null;
  private moveLine: Partial<Plotly.Shape>;
  protected shapes: Partial<Plotly.Shape>[];
  private yScale: [number, number];
  private series: string[];
  private tracesIndices: number[];
  protected seriesXData: number[][];
  protected seriesYData: number[][];

  get scaleMin() {
    return this.moveLine.y0;
  }

  get scaleMax() {
    return this.moveLine.y1;
  }

  constructor(
    protected options: Partial<LineChartOptions>,
    series?: string[],
    translateSerivce?: TranslateService
  ) {
    this.time = 0;
    this.moveLine = {
      x0: 0,
      x1: 0,
      type: "line",
      y0: 0,
      y1: 100,
      line: {
        color: "#888888",
        width: 3
      }
    };
    this.shapes = options.includeMoveLine ? [this.moveLine] : [];
    this.series = series ?? [];
    this.seriesXData = this.series.map(() => []);
    this.seriesYData = this.series.map(() => []);
    this.tracesIndices = this.series.map((_, i) => i + 1);

    if (!options.container) {
      return;
    }

    Plotly.newPlot(options.container, [{
      x: [],
      y: [],
      mode: "lines",
      type: "scattergl",
      line: { color: "#ff0000" }
    }, ...this.series.map((v) => ({
      x: [],
      y: [],
      mode: "lines",
      type: "scattergl",
      line: { color: v }
    }) as Partial<Plotly.ScatterData>)], {
      margin: {
        b: 30,
        t: 20
      },
      dragmode: false,
      hovermode: false,
      clickmode: "none",
      hidesources: true,
      yaxis: {
          range: [0, 100],
          autorange: false,
          automargin: false,

          // This improves the performance of tikcs rendering
          // @ts-ignore
          "ticklabeloverflow": "allow"
      },
      xaxis: {
          range: [0, this.options.chartConfig.horizont ?? 30],
          autorange: false,
          automargin: false,

          // This improves the performance of tikcs rendering
          // @ts-ignore
          "ticklabeloverflow": "allow"
      },
      // Disabled due to performance issues
      showlegend: false
    }, {
      editable: false,
      // Static plots are more performant
      staticPlot: true,
      displaylogo: false,
      displayModeBar: false,
      frameMargins: 0,
      watermark: false,
    });

    const emptyAnimationFrame = () => {
      if (this.animationFrame) {
        requestAnimationFrame(this.animationFrame);
      }
    }

    this.animationFrame = () => {
      const horizont = (this.options.chartConfig.horizont ?? 30);
      const halfHorizont = horizont * 0.5;
      const left = this.time - halfHorizont;

      this.moveLine.x0 = this.time;
      this.moveLine.x1 = this.time;

      if (this.series.length === 0) {
        Plotly.relayout(this.options.container, {
          shapes: this.shapes,
          "xaxis.range": [left < 0 ? 0 : left, Math.max(this.time + halfHorizont, horizont)]
        });
      }
      else {
        Plotly.update(this.options.container, {
          x: this.seriesXData,
          y: this.seriesYData
        }, {
          shapes: this.shapes,
          "xaxis.range": [left < 0 ? 0 : left, Math.max(this.time + halfHorizont, horizont)]
        }, this.tracesIndices);
      }

      if (this.animationFrame) {
        requestAnimationFrame(emptyAnimationFrame);
      }
    };

    requestAnimationFrame(this.animationFrame);
  }

  setScale({ min, max }: { min: number, max: number }) {
    this.moveLine.y0 = min;
    this.moveLine.y1 = max;

    Plotly.relayout(this.options.container, {
      "yaxis.range": [min, max]
    });
  }

  createGuideLine(points: { x: number, y: number }[], color = '#66cccc') {
    Plotly.restyle(this.options.container, {
      x: [points.map(v => v.x)],
      y: [points.map(v => v.y)],
      "line.color": [color]
    }, [0]);
  }

  setTime(point: number) {
    this.time = point;
  }

  destroy() {
    this.animationFrame = null;
    Plotly.purge(this.options.container);
  }
}
