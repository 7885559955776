import { getChartLabel, secondsToTimeOnCharts } from '@app/utils/utils';
import {
  lightningChart,
  DataPatterns,
  emptyFill,
  emptyLine,
  SolidFill,
  AxisTickStrategies,
  SolidLine,
  ColorRGBA,
  ColorHEX,
  ChartXY, LineSeries, AutoCursorModes, Point, Themes, EmptyFill, NumericTickStrategy, point
} from '@arction/lcjs';
import { environment } from '@env/environment';
import { EgzoChart, ChartOptions, ThresholdLine, MovingTimeAxisScaleStrategy } from './EgzoChart';
import { MoveLineTimeChart2 } from './MoveTimeLineChart2';
import { COLOR_ARRAY } from '@app/utils/ColorDictionary';

interface LineChartOptions extends ChartOptions {
  channels: number;
}

const CHART_TIMEFRAME = 30;

export class LineChart2 extends MoveLineTimeChart2 {
  private rawSeriesYData: number[][];
  private factors: number[];
  private verticalLines: Partial<Plotly.Shape>[];

  thresholdLine = {
    setValue() {

    }
  };

  constructor(options: Partial<LineChartOptions>, xAxis: string, yAxis: string) {
    super({
      ...options,
      chartConfig: {
        horizont: CHART_TIMEFRAME,
        preloadTime: CHART_TIMEFRAME,
        ticks: CHART_TIMEFRAME,
        allowShifting: true
      },
      includeMoveLine: false
    }, COLOR_ARRAY);

    this.rawSeriesYData = COLOR_ARRAY.map(v => []);
    this.factors = [];
    this.verticalLines = [];
  }

  setVisibleChannels(channels: number[]) {
    Plotly.restyle(this.options.container, {
      // @ts-ignore
      visible: Array(8).fill(true).map((_, i) => channels.indexOf(i) >= 0)
    }, Array(8).fill(0).map((_, i) => i + 1));
  }

  setCurrentChannel(channel: number) {

  }

  addVerticalLine(x: number, color: string) {
    const verticalLine: Partial<Plotly.Shape> = {
      x0: x,
      x1: x,
      type: "line",
      y0: this.scaleMin,
      y1: this.scaleMax,
      line: {
        color,
        width: 3
      }
    };

    this.shapes.push(verticalLine);
    this.verticalLines.push(verticalLine);
  }

  setScale(scale: { min: number; max: number; }): void {
    super.setScale(scale);

    this.verticalLines.forEach(v => { v.y0 = scale.min; v.y1 = scale.max; });
  }

  addValue(value: { x: number, y: number}, channel: number) {
    const seriesX = this.seriesXData[channel];
    const rawSeriesY = this.rawSeriesYData[channel];
    const seriesY = this.seriesYData[channel];

    seriesX.push(value.x);
    rawSeriesY.push(value.y);
    seriesY.push(value.y * (this.factors[channel] ?? 1));

    while (seriesX[0] < value.x - this.options.chartConfig.horizont) {
      seriesX.shift();
      rawSeriesY.shift();
      seriesY.shift();
    }

    this.setTime(value.x);
  }

  setColorArray(newArray: string[]): void {
  }

  resetFactors() {
    this.setFactors([]);
  }

  setFactors(factors: number[]): void {
    this.factors = factors;

    for (const channel in factors) {
      const seriesY = this.seriesYData[channel];
      const rawSeriesY = this.rawSeriesYData[channel];

      for (let i = 0; i < seriesY.length; i++) {
        seriesY[i] = rawSeriesY[i] * (factors[channel] ?? 1);
      }
    }
  }

  setYAxisStrategy(strategy: string) {

  }

  setYAxisTitle(title: string) {

  }

  createGuideLine(points: { x: number; y: number; }[], color?: string): void {
    super.createGuideLine(rectangularizeSeries(points), color);
  }
}


function rectangularizeSeries(points: Point[]): Point[] {
  const newPoints = [];
  points.reduce((previous, next) => {
    if (!previous) {
      return next;
    }
    newPoints.push({
      x: next.x,
      y: previous.y
    });
    newPoints.push(next);
    return next;
  });

  return newPoints;
}
